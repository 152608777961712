<script>
import Layout from "../../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Helper from '@/helpers/helper';
import { ref } from "vue";
import Swal from 'sweetalert2'


/**
 * Dashboard Component
 */
export default {
  setup() {
        const doesAddOpen = ref(false);
        const doesEditOpen = ref(false);
        const doesDeleteOpen = ref(false);


        return {
            doesAddOpen,
            doesEditOpen,
            doesDeleteOpen,

        };
    },
    page: {
    title: "Evènement",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Formulaire",
      items: [
        {
          text: "Menu",
          href: "/",
        },
        {
          text: "Formulaire",
          active: true,
        },
        {
          text: "Une question",
          active: true,
        },
      ],
      formular_quiz: {},
      modality:{},
      formular:{},
      libelle : "",
      loading: false,
      errors: []
    };
  },
  methods: {
    getItem() {
      fetch(Helper.route('formular-quizzes/'+this.id), Helper.requestOptions())
      .then(response => {
          Helper.tokenChecker(response.status)
          console.log('ayyyy')
          return response.json()
      })
      .then(data => {
        console.log('formular_quiz', data.data);
        this.formular_quiz = data.data
        this.formular = this.formular_quiz.formular
        console.log('ayyy', this.formular_quiz.formular)
      })
    },

    addItem() {
      this.loading = true
      this.errors = []
      let formData = new FormData()
      formData.append('libelle', this.libelle)
      formData.append("formular_quiz_id", this.formular_quiz.id)
      fetch(Helper.route('modalities'), Helper.requestOptions("POST", formData))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        console.log(this.status);
        console.log(data);
        this.loading = false
        if (this.status == 201) {
          this.quiz = ""
          this.doesAddOpen = false
          this.formular_quiz.modalities.push(data.data)
          Swal.fire("Modalité ajouter !", "La modalité a été enregistrée avec succès.", "success");
        } else {
          this.errors.quiz = Helper.getError(data.data, "quiz")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },


    deleteIt() {
      this.loading = true
      fetch(Helper.route('modalities/'+this.modality.id), Helper.requestOptions("DELETE"))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.text()
      })
      .then(data => {
        console.log(this.status);
        console.log(data);
        this.loading = false
        this.doesDeleteOpen = false
        if (this.status == 204) {
          this.formular_quiz.modalities = this.formular_quiz.modalities.filter(i => i.id !== this.modality.id)
          Swal.fire("Modalite supprimer !", "La modalite a été supprimé avec succès.", "success");
        } else {
          Swal.fire("Modalite non supprimer ?", "La suppression n'a pas marché, veuillez réessayez plus tard.", "error");
        }
      })
    },


    initItem(modality, action="edit") {
      this.modality = modality
      if (action == "delete") {
        this.doesDeleteOpen = true
      } else {
        this.doesEditOpen = true
      }
    }


  },
  mounted() {
    this.id = this.$route.params.id
    this.getItem()
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="hstack mb-3" v-if="formular.is_published == 0">
        
        <b-button v-if="formular_quiz.type == 'MODALITE'" variant="primary" class="ms-3" @click="doesAddOpen = true">
            <i class="bx bx-plus font-size-16 align-middle me-2"></i>
            Ajouter une nouvelle modalité
        </b-button>
    </div>
    
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Listes des réponses de la question</h4>
            <p class="card-title-desc">
              Le tableau ci-dessous présente la listes des <code>réponses</code> de la question <code>{{ formular_quiz.quiz }}</code>.
            </p>
            

            <div>

                <div class="table-responsive">
                    <table class="table align-middle mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Entité</th>
                                <th>Réponse</th>
                                <th>Date de réponse</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(formular_response, i) in formular_quiz.formular_responses" :key="formular_response.id">
                                <th scope="row">{{ i + 1 }}</th>
                                <td>
                                    <router-link :to="'/cdn/patners/'+formular_response.entity.id" v-if="formular_response.entity.type == 'PATNER'">{{ formular_response.entity.name }}</router-link>
                                    <router-link :to="'/cdn/sectors/'+formular_response.entity.id" v-else>{{ formular_response.entity.name }}</router-link>
                                </td>
                                <td>{{ formular_response.response }}</td>
                                <td>{{ formular_response.created_at }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

            
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Listes des modalités de la question</h4>
            <p class="card-title-desc">
              Le tableau ci-dessous présente la listes des <code>modalités</code> de la question <code>{{ formular_quiz.quiz }}</code>.
            </p>
            

            <div>

                <div class="table-responsive">
                    <table class="table align-middle mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Libelle</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(quiz_modalite, i) in formular_quiz.modalities" :key="quiz_modalite.id">
                                <th scope="row">{{ i + 1 }}</th>
                                <td>
                                    {{ quiz_modalite.libelle }}
                                </td>
                                <td v-if="!formular.is_published" class="hstack">
                                  <b-button variant="danger" @click="initItem(quiz_modalite, 'delete')" class="ms-2 btn-sm">
                                      <i class="bx bx-trash font-size-16 align-middle mx-1"></i>
                                  </b-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

            
          </div>
        </div>
      </div>
    </div>


    <!-- ADD MODALITE -->
    <b-modal
        v-model="doesAddOpen"
        id="modal-center"
        centered
        title="Ajouter une modalité"
        title-class="font-18"
        hide-footer
        >
        <form @submit.prevent="addItem()">
          <div>
            <label for="">Libelle</label>
            <input type="text" required v-model="libelle" class="form-control">
            <span class="text-danger mt-2" v-if="errors.libelle">{{ errors.libelle }}</span>
          </div>
          
          <div class="mt-3" v-if="!loading">
            <input type="submit" value="Enregistrer" class="btn btn-primary">
          </div>
          <div class="mt-3 text-center" v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </form>
    </b-modal>

    <!-- DELETE MODALITE -->
    <b-modal
        v-model="doesDeleteOpen"
        id="modal-center"
        title="Supprimer la modalité"
        title-class="font-18"
        hide-footer
        >
        <p class="mb-2">
            Voulez-vous vraiment supprimer cette modalité ?
        </p>
        <div class="hstack mt-2" v-if="!loading">
          <button class="btn btn-dark ms-auto" @click="doesDeleteOpen = false">Annuler</button>
          <button class="ms-3 btn btn-danger" @click="deleteIt()">Supprimer</button>
        </div>
        <div class="text-center" v-else>
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
    </b-modal>


  </Layout>
</template>
